
import { defineComponent, ref, reactive, toRefs, inject, nextTick } from 'vue'
import { ElMessage } from 'element-plus'
import { jobsApi } from '@/api/modules/jobs'

export default defineComponent({
  name: 'positionAddOrEditDialog',
  setup (props, ctx) {
    // 获取refs
    const addOrEditFormRef = ref()

    // 接收父级的方法
    const updateList: any = inject('updateList')

    // 新增、编辑表单数据
    const addOrEditFormInit = () => {
      return {
        name: ''
      }
    }

    const data: any = reactive({
      addOrEditDialog: false,
      addOrEditType: 0, // 0新增,1编辑
      addOrEditTypeTitle: ['新增', '编辑'],
      addOrEditForm: addOrEditFormInit(),
      addOrEditRules: {
        name: [{ required: true, message: '请输入岗位名称', trigger: 'blur' }]
      },
      determineBtnLoading: false,

      // 对话框显示
      async showAddOrEdit (id: number) {
        data.addOrEditType = id ? 1 : 0

        if (id) {
          // 有id时赋值
          const { res } = await jobsApi.getInfo({ id })
          console.log('根据id获取详情', res)
          data.addOrEditForm = res
        }
        data.addOrEditDialog = true
      },
      // 对话框关闭
      addOrEditCancel () {
        // console.log('对话框关闭')
        addOrEditFormRef.value.resetFields()
        data.addOrEditForm = Object.assign({}, addOrEditFormInit())
      },
      // 对话框确认
      addOrEditDetermine () {
        console.log('data.addOrEditForm', data.addOrEditForm)

        addOrEditFormRef.value.validate(async (valid: any) => {
          if (!valid) return

          data.determineBtnLoading = true
          await jobsApi.save(data.addOrEditForm)
          ElMessage.success(`${data.addOrEditTypeTitle[data.addOrEditType]}成功`)
          data.addOrEditDialog = false
          data.determineBtnLoading = false

          // 父级更新列表
          updateList('place')
        })
      }
    })

    return {
      ...toRefs(data),
      addOrEditFormRef
    }
  }
})
